




















import Vue from "vue";

export default Vue.extend({
  name: "SideBar",
  props:['isOpen'],
  data(){
      return{
        open: this.isOpen
      }
  },
  watch:{
    isOpen: function (val) {
        this.open = val
    },
  },
  components: {
  },
  methods:{
    contactUs(){
      this.$router.push({name:'Contact'})
      this.close()
    },  
    close(){
    this.$emit('close')
    }
  }
});
