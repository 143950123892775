








import Vue from "vue";
import NavBar from "./components/nav-bar/NavBar.vue";
import SideBar from "./components/side-bar/SideBar.vue";
export default Vue.extend({
  components: {
    "nav-bar": NavBar,
    "side-bar": SideBar
  },
  data(){
    return{
      isOpen: false
    }
  },
  methods:{
    open(){
      this.isOpen = true;
    },
    close(){
      this.isOpen = false
    }
  }
});

