



























import Vue from "vue";

export default Vue.extend({
  name: "NavBar",
  components: {
  },
  methods:{
    open(){
      this.$emit('open')
    },
    contactUs(){
      this.$router.push({name:'Contact'})
    }
  }
});
