import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "/services/engineering",
    name: "Engineering",
    component: () => import(/* webpackChunkName: "engineering" */ "../views/services/Engineering.vue")
  },
  {
    path: "/services/procurement",
    name: "Procurement",
    component: () => import(/* webpackChunkName: "engineering" */ "../views/services/Procurement.vue")
  },
  {
    path: "/services/installation",
    name: "Operations",
    component: () => import(/* webpackChunkName: "engineering" */ "../views/services/Operations.vue")
  },
  {
    path: "/services/construction",
    name: "Construction",
    component: () => import(/* webpackChunkName: "engineering" */ "../views/services/Construction.vue")
  },
  {
    path: "/qhse",
    name: "HealthSafety",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "qhse" */ "../views/HealthSafety.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
