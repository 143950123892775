



































































































































import Vue from "vue";
import Footer from "../components/footer/Footer.vue"
export default Vue.extend({
  name: "Home",
  components: {
    Footer
  },
  methods:{
    gotoService(){
      this.$router.push({name: 'Services'})
    },
    gotoContact(){
      this.$router.push({name: 'Contact'})
    },
    gotoAbout(){
      this.$router.push({name: 'About'})
    },
    gotoEngineering(){
      this.$router.push({name: 'Engineering'})
    },
    gotoProcurement(){
      this.$router.push({name: 'Procurement'})
    },
    gotoConstruction(){
      this.$router.push({name: 'Construction'})
    },
    gotoInstallation(){
      this.$router.push({name: 'Operations'})
    }
  }
});
