import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Sidebar from 'primevue/sidebar';
import { initializeApp } from "firebase/app";

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css' 

Vue.use(PrimeVue);
Vue.component('p-accordion', Accordion);
Vue.component('p-accordion-tab', AccordionTab);
Vue.component('p-side-bar', Sidebar);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyB79X1XdG4eo-T1bpotc80SzMnot6KnTRc",
  // authDomain: "redcrystal-7b535.firebaseapp.com",
  projectId: "redcrystal-7b535",
  // storageBucket: "redcrystal-7b535.appspot.com",
  // messagingSenderId: "505649483347",
  appId: "1:505649483347:web:d52a165e7482730e9f40a6"
};

const app = initializeApp(firebaseConfig);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
