










































import Vue from "vue";

export default Vue.extend({
  name: "Footer",
  components: {
  },
  data(){
    return{
      year:''
    }
  },
  mounted(){
    this.year = new Date().getFullYear().toString()
  }
});
